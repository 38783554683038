@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "./theme.less";

// TODO remove when https://github.com/facebook/create-react-app/issues/11773 is fixed
body > iframe {pointer-events: none;}

h1 {
  font-size: 24px;
}

.flex {
  display: flex;
}

.sub-title-light {
  font-weight: lighter;
}

.rgpd-infos {
  font-weight: lighter;
  font-size: 9px;
}

.row-clickable {
  cursor: pointer;
}
.row-disabled {
  color: #00000040;
}

.ant-card-head-title svg:first-of-type {
  margin-right: 5px;
}

.button-success,
.button-success:focus {
  color: @success-color;
  border-color: @success-color;
}
.button-success:hover {
  color: @green-4;
  border-color: @green-4;
}

.ant-modal-title svg:first-of-type {
  margin-right: 5px;
}

.ant-row.ant-form-item.horizontal-6,
.ant-row.ant-form-item.horizontal-12,
.ant-row.ant-form-item.horizontal-18 {
  flex-direction: row;

  > div.ant-form-item-label,
  > div.ant-form-item-control {
    display: block;
  }

  > div.ant-form-item-label {
    padding: 0 !important;
    > label {
      height: 32px !important;
    }
  }
}

.ant-row.ant-form-item.horizontal-6 {
  > div.ant-form-item-label {
    flex: 0 0 25%;
    max-width: 25%;
  }
  > div.ant-form-item-control {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.ant-row.ant-form-item.horizontal-12 {
  > div.ant-form-item-label {
    flex: 0 0 50%;
    max-width: 50%;
  }
  > div.ant-form-item-control {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.ant-row.ant-form-item.horizontal-18 {
  > div.ant-form-item-label {
    flex: 0 0 75%;
    max-width: 75%;
  }
  > div.ant-form-item-control {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.input-number-euro::after {
  content: "€";
  position: absolute;
  top: 4px;
  right: -15px;
}

.actions-column button {
  margin-right: 8px;
}
.actions-column button:last-child {
  margin-right: 0px;
}

.form-item-no-explain {
  margin-bottom: 0px;
  .ant-form-item-explain {
    display: none;
  }
}

.ant-tabs-tab .ant-tag .svg-inline--fa {
  margin-right: 0;
}

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
  border-left: 4px solid rgb(0, 111, 145);
}

.draggable-modal .ant-modal-header {
  cursor: move;
}