@import './static/NoirPro/stylesheet.css';


// Base
@text-color: #4d5f71;
@font-family: 'NoirPro', sans-serif;
@heading-color: #006f91;
@primary-color: #006f91;
@alert-message-color: @text-color;

h1, h2, h3, h4 {
  font-weight: lighter;
}


// Layout App (global)
.app-layout {
  min-height: 100vh;
}


// Layout Sider
@layout-sider-background: #006f91;
@layout-sider-width: 280px;

.app-layout > .ant-layout-sider {
  width: @layout-sider-width !important;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
}
.app-layout > .ant-layout-sider-collapsed {
  width: 80px !important;
}
.app-layout > .ant-layout-sider > .ant-layout-sider-trigger {
  width: @layout-sider-width !important;
}
.app-layout > .ant-layout-sider-collapsed > .ant-layout-sider-trigger {
  width: 80px !important;
}
.sider-header {
  padding: 10px 30px 10px 70px;
  height: 72px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  box-shadow: none;
}
.sider-header img {
  max-width: 100%;
}
.sider-collapse-btn {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
}
.sider-collapse-btn:hover {
  background-color: #006f91;
}
.ant-layout-sider-collapsed .sider-collapse-btn {
  left: 20px;
}
.sider-content {
  height: calc(100vh - 72px) !important;
  overflow: hidden auto;
}


// Layout header/content/footer
.app-layout > section.ant-layout {
  flex: 1 1 auto;
  overflow: auto;
  height: 100vh;
}


// Layout header
@layout-header-background: #fefefe;
@layout-header-color: #262626;
@layout-header-padding: 0 32px;
@layout-header-height: 72px;

.ant-layout-header {
  box-shadow: 0 0 4px 4px rgba(0,0,0,0.08);
  align-items: center;
  line-height: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}


// Layout Content
@layout-body-background: #f5f5f5;

.ant-layout-content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.main-content-wrapper {
  padding: 32px 32px;
  flex: 1;
}


// Layout Footer
@layout-footer-padding: 14px 32px;
@layout-footer-background: #ffffff;

.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
  text-align: center;
}


// Menu
@menu-dark-bg: #006f91;
@menu-dark-color: #ffffff;
@menu-dark-highlight-color: #f8aa00;
@menu-dark-item-active-bg: transparent;
@menu-dark-arrow-color: #ffffff;
@menu-dark-inline-submenu-bg: #006f91;
@menu-icon-size: 18px;
@menu-item-height: 35px;
@menu-dark-selected-item-icon-color: #f8aa00;
@menu-dark-selected-item-text-color: #f8aa00;

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: @menu-dark-highlight-color;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #f8aa00;
}


// Page header
.ant-page-header {
  padding: 0 0 16px 0
}
.ant-page-header-heading-title {
  font-weight: lighter;
  font-size: 24px;
}
.ant-page-header-heading-title > svg:first-child {
  margin-right: 6px;
}


// Misc
.ant-dropdown-link {
  cursor: pointer;
}
.ant-input-prefix .svg-inline--fa {
  color: rgba(0, 0, 0, 0.25);
  margin-right: 3px;
}
.ant-result-title {
  font-weight: lighter;
}
.ant-card {
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
  border-radius: 10px;
}


/**
 * Copy iconfont rules for fontawesome
 */
 @iconfont-css-prefix2: svg-inline--fa;

// Alert
.@{alert-prefix-cls} {
  &-close-icon {
    .@{iconfont-css-prefix2}-close {
      color: @alert-close-color;
      transition: color 0.3s;
      &:hover {
        color: @alert-close-hover-color;
      }
    }
  }
}
// Avatar
.avatar-size(@size, @font-size) {
  &.@{avatar-prefix-cls}-icon {
    > .@{iconfont-css-prefix2} {
      margin: 0;
    }
  }
}
// Badge
.@{badge-prefix-cls} {
  &-count,
  &-dot,
  .@{number-prefix-cls}-custom-component {
    &.@{iconfont-css-prefix2}-spin {
      animation: antBadgeLoadingCircle 1s infinite linear;
    }
  }
}
// Button
.@{btn-prefix-cls} {
  .@{iconfont-css-prefix2} {
    transition: margin-left 0.3s @ease-in-out;
    &.@{iconfont-css-prefix2}-plus,
    &.@{iconfont-css-prefix2}-minus {
      > svg {
        shape-rendering: optimizeSpeed;
      }
    }
  }
  & > &-loading-icon {
    .@{iconfont-css-prefix2} {
      padding-right: @padding-xs;
      animation: none;
      svg {
        animation: loadingCircle 1s infinite linear;
      }
    }
    &:only-child {
      .@{iconfont-css-prefix2} {
        padding-right: 0;
      }
    }
  }
  > .@{iconfont-css-prefix2} + span,
  > span + .@{iconfont-css-prefix2} {
    margin-left: @margin-xs;
  }
  &-two-chinese-chars > *:not(.@{iconfont-css-prefix2}) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
}
// Card
.@{card-prefix-cls} {
  &-actions {
    & > li {
      > span {
        a:not(.@{ant-prefix}-btn),
        > .@{iconfont-css-prefix2} {
          display: inline-block;
          width: 100%;
          color: @text-color-secondary;
          line-height: 22px;
          transition: color 0.3s;
          &:hover {
            color: @primary-color;
          }
        }
        > .@{iconfont-css-prefix2} {
          font-size: @card-action-icon-size;
          line-height: 22px;
        }
      }
    }
  }
}
// Dropdown
.@{dropdown-prefix-cls} {
  &-wrap {
    .@{ant-prefix}-btn > .@{iconfont-css-prefix2}-down {
      font-size: 10px;
    }
    .@{iconfont-css-prefix2}-down::before {
      transition: transform 0.2s;
    }
  }
  &-wrap-open {
    .@{iconfont-css-prefix2}-down::before {
      transform: rotate(180deg);
    }
  }
  &-menu {
    &-item,
    &-submenu-title {
      > .@{iconfont-css-prefix2}:first-child,
      > a > .@{iconfont-css-prefix2}:first-child,
      > span > .@{iconfont-css-prefix2}:first-child {
        min-width: 12px;
        margin-right: 8px;
        font-size: @font-size-sm;
        vertical-align: -0.1em;
      }
      > .@{iconfont-css-prefix2} + span > a {
        color: @text-color;
        transition: all 0.3s;
        &:hover {
          color: @text-color;
        }
      }
      &-disabled {
        > .@{iconfont-css-prefix2} + span > a,
        > a {
          position: relative;
          color: @disabled-color;
          pointer-events: none;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: not-allowed;
            content: '';
          }
        }
      }
    }
  }
}
.@{dropdown-prefix-cls}-trigger,
.@{dropdown-prefix-cls}-link,
.@{dropdown-prefix-cls}-button {
  > .@{iconfont-css-prefix2}.@{iconfont-css-prefix2}-down {
    font-size: 10px;
    vertical-align: baseline;
  }
}
.@{dropdown-prefix-cls}-menu-dark {
  .@{dropdown-prefix-cls}-menu-item,
  .@{dropdown-prefix-cls}-menu-submenu-title,
  .@{dropdown-prefix-cls}-menu-item > a,
  .@{dropdown-prefix-cls}-menu-item > .@{iconfont-css-prefix2} + span > a {
    color: @text-color-secondary-dark;
    .@{dropdown-prefix-cls}-menu-submenu-arrow::after {
      color: @text-color-secondary-dark;
    }
    &:hover {
      color: @text-color-inverse;
      background: transparent;
    }
  }
}
// Form
.@{form-item-prefix-cls} {
  &-label {
    > label {
      > .@{iconfont-css-prefix2} {
        font-size: @form-item-label-font-size;
        vertical-align: top;
      }
    }
  }
}
// Image
.@{image-prefix-cls} {
  &-mask {
    &-info {
      .@{iconfont-css-prefix2} {
        margin-inline-end: @margin-xss;
      }
    }
  }
  &-preview {
    &-switch-left,
    &-switch-right {
      &-disabled {
        > .@{iconfont-css-prefix2} {
          cursor: not-allowed;
        }
      }
      > .@{iconfont-css-prefix2} {
        font-size: 18px;
      }
    }
  }
}
// Menu
.@{menu-prefix-cls} {
  &-dark &-item-selected {
    .@{menu-prefix-cls}-item-icon,
    .@{iconfont-css-prefix2} {
      color: @menu-dark-selected-item-icon-color;

      + span {
        color: @menu-dark-selected-item-text-color;
      }
    }
  }
}
.@{menu-prefix-cls} {
  &-item,
  &-submenu-title {
    .@{menu-prefix-cls}-item-icon,
    .@{iconfont-css-prefix2} {
      min-width: 14px;
      margin-right: @menu-icon-margin-right;
      font-size: @menu-icon-size;
      transition: font-size 0.15s @ease-out, margin 0.3s @ease-in-out, color 0.3s;
      + span {
        opacity: 1;
        transition: opacity 0.3s @ease-in-out, width 0.3s @ease-in-out, color 0.3s;
      }
    }
    &.@{menu-prefix-cls}-item-only-child {
      > .@{iconfont-css-prefix2},
      > .@{menu-prefix-cls}-item-icon {
        margin-right: 0;
      }
    }
  }
  &-inline-collapsed {
    > .@{menu-prefix-cls}-submenu > .@{menu-prefix-cls}-submenu-title {
      .@{menu-prefix-cls}-item-icon,
      .@{iconfont-css-prefix2} {
        margin: 0;
        font-size: @menu-icon-size-lg;
        line-height: @menu-item-height;
        + span {
          display: inline-block;
          max-width: 0;
          opacity: 0;
        }
      }
    }
    .@{menu-prefix-cls}-item-icon,
    .@{iconfont-css-prefix2} {
      display: inline-block;
    }
    &-tooltip {
      .@{menu-prefix-cls}-item-icon,
      .@{iconfont-css-prefix2} {
        display: none;
      }
    }
  }
}
// Message
.@{message-prefix-cls} {
  &-success .@{iconfont-css-prefix2} {
    color: @success-color;
  }
  &-error .@{iconfont-css-prefix2} {
    color: @error-color;
  }
  &-warning .@{iconfont-css-prefix2} {
    color: @warning-color;
  }
  &-info .@{iconfont-css-prefix2},
  &-loading .@{iconfont-css-prefix2} {
    color: @info-color;
  }
  .@{iconfont-css-prefix2} {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: @font-size-lg;
  }
}
// Confirm
.@{confirm-prefix-cls} {
  &-body {
    > .@{iconfont-css-prefix2} {
      float: left;
      margin-right: 16px;
      font-size: 22px;

      // `content` after `icon` should set marginLeft
      + .@{confirm-prefix-cls}-title + .@{confirm-prefix-cls}-content {
        margin-left: 38px;
      }
    }
  }
  &-error &-body > .@{iconfont-css-prefix2} {
    color: @error-color;
  }
  &-warning &-body > .@{iconfont-css-prefix2},
  &-confirm &-body > .@{iconfont-css-prefix2} {
    color: @warning-color;
  }
  &-info &-body > .@{iconfont-css-prefix2} {
    color: @info-color;
  }
  &-success &-body > .@{iconfont-css-prefix2} {
    color: @success-color;
  }
}
// Notification
.@{notification-prefix-cls} {
  &-notice {
    .@{iconfont-css-prefix2}&-icon {
      &-success {
        color: @success-color;
      }
      &-info {
        color: @info-color;
      }
      &-warning {
        color: @warning-color;
      }
      &-error {
        color: @error-color;
      }
    }
  }
}
// Popover
.@{popover-prefix-cls} {
  &-message {
    > .@{iconfont-css-prefix2} {
      position: absolute;
      top: (
        4px + ((@line-height-base * @font-size-base - @font-size-base) / 2)
      );
      color: @warning-color;
      font-size: @font-size-base;
    }
  }
}
// Progress
.@{progress-prefix-cls} {
  &-small&-line,
  &-small&-line &-text .@{iconfont-css-prefix2} {
    font-size: @font-size-sm;
  }
  &-text {
    .@{iconfont-css-prefix2} {
      font-size: @font-size-base;
    }
  }
  &-circle &-text {
    .@{iconfont-css-prefix2} {
      font-size: (14 / 12em);
    }
  }
}
// Rate
.@{rate-prefix-cls} {
  &-star {
    &-first,
    &-second {
      .@{iconfont-css-prefix2} {
        vertical-align: middle;
      }
    }
  }
}
// Result
.@{result-prefix-cls} {
  &-success &-icon > .@{iconfont-css-prefix2} {
    color: @success-color;
  }
  &-error &-icon > .@{iconfont-css-prefix2} {
    color: @error-color;
  }
  &-info &-icon > .@{iconfont-css-prefix2} {
    color: @info-color;
  }
  &-warning &-icon > .@{iconfont-css-prefix2} {
    color: @warning-color;
  }
  &-icon {
    > .@{iconfont-css-prefix2} {
      font-size: @result-icon-font-size;
    }
  }
}
// Select
.@{select-prefix-cls} {
  &-arrow {
    .@{iconfont-css-prefix2} {
      vertical-align: top;
      transition: transform 0.3s;
      > svg {
        vertical-align: top;
      }
      &:not(.@{select-prefix-cls}-suffix) {
        pointer-events: auto;
      }
    }
  }
}
// Select Multiple
.@{select-prefix-cls} {
  &-multiple {
    .@{select-prefix-cls}-selection-item {
      &-remove {
        > .@{iconfont-css-prefix2} {
          vertical-align: -0.2em;
        }
      }
    }
  }
}
// table
.@{table-prefix-cls} {
  &-filter-trigger {
    .@{iconfont-css-prefix2} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-selection {
    &-extra {
      .@{iconfont-css-prefix2} {
        color: @table-header-icon-color;
        font-size: 10px;
        &:hover {
          color: @table-header-icon-color-hover;
        }
      }
    }
  }
}
// Tabs
.@{tab-prefix-cls} {
  &-tab {
    & &-remove .@{iconfont-css-prefix2} {
      margin: 0;
    }
    .@{iconfont-css-prefix2} {
      margin-right: @margin-sm;
    }
  }
}
// Tag
.@{tag-prefix-cls} {
  &-has-color {
    &,
    a,
    a:hover,
    .@{iconfont-css-prefix2}-close,
    .@{iconfont-css-prefix2}-close:hover {
      color: @text-color-inverse;
    }
  }
  > .@{iconfont-css-prefix2} + span,
  > span + .@{iconfont-css-prefix2} {
    margin-left: 7px;
  }
}