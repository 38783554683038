.test-env.ant-layout-header {
  background-color: #faad14;

  .env-message {
    display: inline;
    margin-left: -217px;
    font-size: 35px;
  }

  .ant-select {
    .ant-select-selection-placeholder {
      color: #000;
    }
  }

  .search-menu {
    svg {
      color: #000;
    }
  }
}

.env-message {
  display: none;
}

.ant-layout-header {
  justify-content: space-between;
}

.search-menu {
  .ant-select {
    width: 400px;

    .ant-select-selection-placeholder {
      color: #bfbfbfa8;
    }
  }

  svg {
    color: #bfbfbfa8;
    margin: 0 10px;
  }

  .ant-input-group-addon,
  .ant-select-selector,
  .ant-select-selector:focus {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
}

.search-menu-dropdown {
  border-radius: 8px;
}

.user-menu {
  height: 100%;
  padding: 16px;
}

.user-menu:hover {
  background-color: #fafafa;
}

.user-menu .ant-avatar {
  margin-right: 7px;
}

.sider-content {
  padding-top: 25px;
}

.info-modal .ant-modal-confirm-content>div {
  text-align: center;
  margin-left: -38px;
  margin-top: 20px;
}

.info-modal .ant-modal-confirm-content img {
  max-width: 80px;
  margin-bottom: 15px;
}

.info-modal .ant-modal-confirm-btns {
  display: none;
}

.ant-layout-footer img {
  max-height: 14px;
}


.recents-menu {

  .ant-menu-item-group-title {
    font-size: 13px;
    padding-top: 0;

    button {
      color: #ffffff;
      width: 22px;
      height: 22px;
      line-height: 22px;
      vertical-align: middle;
      float: right;

      :hover {
        color: #f8aa00;
      }
    }
  }

  .ant-menu-item {
    font-size: 13px;
    height: 25px;
    line-height: 25px;
    margin-top: 2px;
    margin-bottom: 2px !important;

    .ant-menu-item-icon {
      font-size: 16px;
    }
  }

}

.recents-menu-divider {
  margin: 20px 0 !important;
  background-color: #b3b3b3b3 !important;
}