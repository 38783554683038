@font-face {
    font-family: "NoirPro";
    src: url("NoirPro-Light.eot?#iefix") format("embedded-opentype"),
        url("NoirPro-Light.woff") format("woff"),
        url("NoirPro-Light.woff2") format("woff2");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "NoirPro";
    src: url("NoirPro-Regular.eot?#iefix") format("embedded-opentype"),
        url("NoirPro-Regular.woff") format("woff"),
        url("NoirPro-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "NoirPro";
    src: url("NoirPro-Medium.eot?#iefix") format("embedded-opentype"),
        url("NoirPro-Medium.woff") format("woff"),
        url("NoirPro-Medium.woff2") format("woff2");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "NoirPro";
    src: url("NoirPro-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("NoirPro-SemiBold.woff") format("woff"),
        url("NoirPro-SemiBold.woff2") format("woff2");
    font-style: normal;
    font-weight: 600;
}