blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  font-size: 12px;
}

.slate-content {
  border: 1px solid #d9d9d9;
  padding: 11px;
  border-radius: 2px;
  transition: all 0.3s;
}

.slate-menu {
  margin-bottom: 4px;
}

.slate-menu button {
  margin-right: 4px;
}