.row-payment-ignore {
  color: #d9d9d9; // Gray-5
}
.row-payment-apply {
  background-color: #d9f7be; // Green-2
  &:hover > td {
    background-color: #b7eb8f !important; // Green-3
  }
}
.row-payment-refund {
  background-color: #fff1b8; // Gold-2
  &:hover > td {
    background-color: #ffe58f !important; // Gold-3
  }
}