.registration-descriptions {

  .ant-table-wrapper {
    margin-top: 5px;
    margin-bottom: 3px;
  }

  table {
    width: 100% !important;
  }

  .ant-list-item {
    padding: 8px 0;
  }

  .ant-list-item-meta {
    
    .ant-list-item-meta-content {
      display: flex;
      flex-direction: row;
    }

    .ant-list-item-meta-title {
      font-weight: normal;
      text-decoration: underline;
      margin-bottom: 7px;
    }
  
    .ant-list-item-meta-description {
      margin-left: 30px;
    }

  }

  .ant-list-item-action {
    align-self: flex-start;
  }

  .file-item {
    display: flex;
    align-items: center;
    gap: 7px;
  }

}