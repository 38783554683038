.site-card {
  width: 400px;
  border-radius: 2px;

  .ant-card-meta-title {
    white-space: normal;
  }

  .ant-card-meta {
    margin-top: 15px;
  }

  .ant-card-meta:first-of-type {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.trainers-list {
  margin: 0;
  padding-left: 15px;
}