.partner-card {

  border-radius: 2px;
  width: 200px;

  .ant-card-cover {

    width: 200px;
    height: 200px;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      max-height: 180px;
      max-width: 180px;
      display: inline-block;
    }

  }

}

.logo-upload + .ant-upload-list .ant-upload-list-item-thumbnail {

  text-align: center;
  line-height: 48px !important;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .ant-upload-list-item-image {
    display: inline-block !important;
    vertical-align: middle;
    max-height: 48px;
    max-width: 48px;
    height: unset !important;
    width: unset !important;
  }

}