.payment-modal {

  .price-list {
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  @price-card-height: 250px;
  @price-card-footer-height: 75px;

  .price-card {
    width: 200px;
    height: @price-card-height;
    margin: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-align: center;
    position: relative;

    .price-card-content {
      height: @price-card-height - @price-card-footer-height;
      padding: 15px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: lighter;
    }

    .price-card-footer {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: @price-card-footer-height;
      color: white;
      padding: 15px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #006f91;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .price-card-disabled {
    opacity: 0.5;
    cursor: initial;

    &:hover {
      transform: none;
    }
  }

}