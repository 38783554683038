.portal-layout {
  display: flex;
  justify-content: center;
  flex-direction: row;

  .ant-layout {
    min-height: 100vh;
    max-width: 1000px;
    width: 1000px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    z-index: 5;
    position: relative;
  }

  @header-height: 120px + 53px;

  .header {
    position: absolute;
    width: 100%;
    height: @header-height;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 7px 5px -5px rgba(0, 0, 0, 0.07);

    .header-blue {
      background-color: #006f91;
      padding: 10px;
      height: 113px;
      img {
        margin: 15px 10px 6px 10px;
        max-width: 250px;
      }
      .user-infos {
        text-align: right;
        margin-right: 20px;
        h2 {
          color: #ffffff;
          font-size: 24px;
          margin-bottom: 0;
        }
        .client-number {
          color: #ffffff;
          font-size: 10px;
          margin: 0 0 10px 0;
        }
        button {
          color: #ffffff;
        }
      }
    }

    .header-border {
      height: 7px;
      display: flex;
      > div {
        flex: 1 1 auto;
      }
    }

    .ant-menu {
      margin-top: 7px;
      padding-left: 10px;
    }

    .ant-menu-item {
      .ant-menu-item-icon {
        margin-right: 0px;
      }
    }

    .ant-menu-item::after {
      right: 10px;
      left: 10px;
    }
  }

  main {
    margin-top: @header-height;
    background-color: #ffffff;
    padding: 35px;

    .ant-card-head {
      font-weight: normal;
    }

    h1 .svg-inline--fa {
      margin-right: 7px;
    }

    .fill-profile-alert {
      margin-bottom: 25px;
      > span.anticon-info-circle {
        margin-top: 5px;
      }
      .ant-alert-message {
        font-size: 22px;
        color: #006f91;
      }
    }
  }

  footer {
    background-color: #006f91;
    text-align: left;
    color: #ffffff;

    .logo-vinci img {
      max-height: 50px;
    }

    .links {
      div {
        float: right;
      }

      a {
        display: block;
        margin-top: 5px;
        color: #ffffff;
      }

      a:first-of-type {
        margin-top: 0px;
      }
    }

    .copyright {
      text-align: center;
      font-size: 11px;
      margin-bottom: -10px;

      img {
        max-height: 12px;
      }
    }
  }
}
