.register-layout {
  display: flex;
  justify-content: center;
  flex-direction: row;

  .ant-layout {
    min-height: 100vh;
    max-width: 1000px;
    width: 1000px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
    z-index: 5;
    position: relative;
  }

  @header-height: 110px + 62px;

  .header {
    position: absolute;
    width: 100%;
    height: @header-height;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 7px 5px -5px rgba(0,0,0,0.07);

    .header-blue {
      background-color: #006f91;
      padding: 10px;
      height: 103px;

      h1, h2, h3 {
        color: #ffffff;
        font-weight: lighter;
        text-align: center;
        margin-bottom: 0;
      }

      h1 {
        font-size: 28px;
      }

      img {
        margin: 10px 10px 6px 10px;
        max-width: 250px;
      }
    }
    
    .header-border {
      height: 7px;
      display: flex;
      width: 100%;
    
      > div {
        flex: 1 1 auto;
      }
    }

    .ant-steps {
      margin-top: 8px;
    }
  }

  main {
    margin-top: @header-height;
    background-color: #ffffff;
    padding: 35px;
  }

  footer {
    background-color: #006f91;
    text-align: left;
    color: #ffffff;

    .logo-vinci img {
      max-height: 50px;
    }

    .links {

      div {
        float: right;
      }

      a {
        display: block;
        margin-top: 5px;
        color: #ffffff;
      }

      a:first-of-type {
        margin-top: 0px;
      }

    }

    .copyright {
      text-align: center;
      font-size: 11px;
      margin-bottom: -10px;

      img {
        max-height: 12px;
      }
    }
  }
}



.register-login {
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  .ant-divider-vertical {
    margin: 0 15px;
  }
  .sub-title-light {
    text-align: center;
    margin-bottom: 20px;
  }
}