.modal-layout-container {
  position: relative;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}

.modal-layout-background {
  position: fixed;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;

  > img {
    position: absolute;
    width: 800px;
    max-width: 50%; 
  }

  > img:nth-of-type(1) {
    bottom: 30px;
    left: 30px;
  }

  > img:nth-of-type(2) {
    top: 30px;
    right: 30px;
    opacity: 0.1;
  }
}


.modal-layout-data {
  width: 94%;
  margin: 10px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
  font-size: 14px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}


.modal-layout-header {
  background-color: #006f91;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  color:#ffffff;

  img.logo {
    max-width: 50%;
    margin: 8px 8px 4px 8px;
    align-self: center;
  }
}


.modal-layout-header-border {
  height: 7px;
  display: flex;
  width: 100%;

  > div {
    flex: 1 1 auto;
  }
}


.modal-layout-content {
  padding: 10px 35px;

  h1 {
    font-weight: lighter;
    font-size: 24px;
    margin: 15px 0;
    text-align: center;
  }
}


.modal-layout-footer {
  background-color: #006f91;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #ffffff;
}