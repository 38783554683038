.action-with-button {

  display: inline-flex;

  button {
    margin-left: 7px;
  }
  
}

.timeline-table {

  .action-icon {
    font-size: 16px;
    color: #006f91;
  }

  .action-icon::before,
  .action-icon::after {
    position: absolute;
    left: 15px;
    height: calc(100% - 31px);
    border-left: 2px solid #f0f0f0;
    content: "";
  }

  .action-icon::before {
    top: 0px;
  }

  .action-icon::after {
    bottom: 0px;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

}